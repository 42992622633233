body {
  margin: 0;
  font-family: 'Sulphur Point', 'sans-serif', 'Chivo', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.outlined{
  text-shadow:
		-1px -1px 0 #ffffff,
		1px -1px 0 #ffffff,
		-1px 1px 0 #ffffff,
		1px 1px 0 #ffffff;
}

a:visited { 
  color: black;
}

a:link {
  color: #8a7f7f
}

.post-link a:link{
color:red;
font-weight: 600;
}

.searchwrap{
  color: grey; 
  text-overflow: ellipsis; 
  overflow: hidden; 
  height: 50px;
  line-height: 16px; 
  position: relative;
  word-wrap: break-word;
}

.search:after{
  content: "...";
  background: #f3f5f6;
  position :absolute;
  right: 0;
  bottom: 2px;
}


.highlight {
  background-color: yellow;
}

#searchtext span{
  background-color:#FF9;
  color:#555;
}