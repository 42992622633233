html {
    height:100%;
    overflow:hidden;
    font-size: 16px;
  }
  body {
      margin: 0;
      padding: 0;
      perspective: 1px;
      transform-style: preserve-3d;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      text-align: center;
  }
  .intro {
      box-sizing: border-box;
      min-height: 100vh;
      padding: 30vw 0 5vw;
      position: relative;
      transform-style: inherit;
      width: 100vw;
  }
  
  .intro h1 {
      margin-top: -100px;
  }
  .intro, .intro:before {
      background: 50% 50% / cover;
      /* animation: coming 0.2s ease-in-out; */
  }

  
  .content {
    margin:-80px 0px 0px auto;
   width:100%;
    position: relative;
    z-index: 1;
    padding:90px 0px
  }
  .container {
    max-width:800px;
    margin:0 auto
  }

  .cellSection{
    display: grid;
    padding:0;
    grid-template-columns: repeat(2, 1fr);
  }

  .cells:nth-child(2n) {
    grid-row-end: span 12;
    height: unset !important;
    background-position: center center !important;
  }

  .content:first-child {
margin-bottom: 70px;
background: red;
  }

  .content:nth-child(3n) {
    margin-top: 0;
      }


  video:focus{
    outline:none
  }


video::-webkit-media-controls-timeline {
  display: none;
}





  .floating {  
        animation-name: floating;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        margin-left: 30px;
        margin-top: 5px;
    }
    
    @keyframes floating {
        from { transform: translate(0,  0px); }
        65%  { transform: translate(0, 35px); }
        to   { transform: translate(0, -0px); }    
    }