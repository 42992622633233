--info.imgUrl

.App {
  text-align: center;
}

* {
  box-sizing: border-box;
}

.reset li{
    display: block;
    margin: 0px;
    padding: 10px;
    cursor: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav{
text-align: center;
padding: 20px;
}
li{
  display: inline;
  margin: 20px;
  padding: 20px;
  cursor: pointer;
}

.mainlinks li{
  margin: 0px;
}

.mainlinks a li{
  padding-right: 0;
}

/* li .fa{
  background: purple
} */


.cells.fullcells:first-child {
  height: 100% !important;
}

.row{
  max-width: var(--wrapper);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: dense;
  grid-auto-rows: minmax(var(--rh), auto);
  grid-gap: var(--gutter);
}

.column {
  grid-column: span 2;
  background-color: white;
  background-size: 110% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  overflow: hidden;
}

.column:hover {
  background-size: 130% 120%;
}


.middle {
  transition: .5s ease;
  opacity: 0;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
  padding-top: 20%;
}

.column:hover .middle {
  opacity: 1;
}

.text {
  /* background-color: #4CAF50; */
  color: white;
  font-size: 16px;
  text-decoration-line: none;
  /* padding: 16px 32px; */

}

a{
text-decoration: none;
}

.column:hover .v3 {
  background: rgba(0, 0, 0, 0.30) !important;
  transition: all 0.6s ease-in-out 0s;
  width: 100%;
  height: 120%;
  /* margin-top: -20px; */
}

.v3{
  margin-top: -20px;
}



:root {
  --wrapper: 135vw;
  --wrap: 135vw;
  --gutter: 0px;
  --rh: calc((var(--wrapper) - (3 * var(--gutter))) / 4);
}



@keyframes nav {
  from { opacity: 0; transform: translate3d(0, -25px, 0) }
  to { opacity: 1; transform: translate3d(0, 0, 0) }
}

nav{
  animation: nav 0.2s ease-in-out forwards;

}

@keyframes coming {
  from { opacity: 0; transform: scale3d(0); }
  to { opacity: 1; transform: scale3d(100); }
}

nav{
  animation: nav 0.2s ease-in-out forwards;

}

@keyframes column {
  from { opacity: 0; transform: translate3d(0, -25px, 0) }
  to { opacity: 1; transform: translate3d(0, 0, 0) }
}


.variant {
  text-align: center;
  height: 100%;
  align-items: center;

}


/* .slide-top {
} */

/* ----------------------------------------------
 * Generated by Animista on 2020-5-6 18:34:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}

#image{
  background-color: orange;
  background-size:auto 100%;
  height: 100%;
      margin-top: -20px;
  padding-top: 20px;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-5-10 14:0:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 /* @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
} */

/* ----------------------------------------------
 * Generated by Animista on 2020-5-10 14:11:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

 @-webkit-keyframes scale-up-center{0%{-webkit-transform:scale(.5);transform:scale(.5)}100%{-webkit-transform:scale(1);transform:scale(1)}}@keyframes scale-up-center{0%{-webkit-transform:scale(.5);transform:scale(.5)}100%{-webkit-transform:scale(1);transform:scale(1)}}

/* .column {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
} */
/* 
! this part scales each card from middle  
*/
/* .column{-webkit-animation:scale-up-center .4s cubic-bezier(.39,.575,.565,1.000) both;animation:scale-up-center .4s cubic-bezier(.39,.575,.565,1.000) both} */


/* ----------------------------------------------
 * Generated by Animista on 2020-5-10 14:10:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-fwd-center
 * ----------------------------------------
 */
 /* @-webkit-keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
            transform: translateZ(160px);
  }
}
@keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
            transform: translateZ(160px);
  }
} */


input {
	outline: none;
}
input[type=search] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
	display: none; 
}


input[type=search] {
	background: #ededed url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
	border: solid 1px #ccc;
	padding: 9px 10px 9px 32px;
	width: 400px;
	
	-webkit-border-radius: 10em;
	-moz-border-radius: 10em;
	border-radius: 10em;
	
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}
input[type=search]:focus {
	width: 130px;
	background-color: #fff;
	border-color: #66CC75;
	
	-webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);
	-moz-box-shadow: 0 0 5px rgba(109,207,246,.5);
	box-shadow: 0 0 5px rgba(109,207,246,.5);
}


input:-moz-placeholder {
	color: #999;
}
input::-webkit-input-placeholder {
	color: #999;
}

/* Demo 2 */
#demo-2 input[type=search] {
	width: 15px;
	padding-left: 10px;
	color: transparent;
	cursor: pointer;
}
#demo-2 input[type=search]:hover {
	background-color: #fff;
}
#demo-2 input[type=search]:focus {
	width: 360px;
	padding-left: 32px;
	color: #000;
	background-color: #fff;
	cursor: auto;
}
#demo-2 input:-moz-placeholder {
	color: transparent;
}
#demo-2 input::-webkit-input-placeholder {
	color: transparent;
}

@media screen and (max-width: 500px) {
  /* ul {
     grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  } */
  .row {

    grid-template-columns: unset;

}
}


/* transition */
.fade-appear,
.fade-enter{
  opacity: 0;
  z-index: 1;
}

.fade-appear-active, .fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms
}

.fade-exit{
  opacity: 1;
}

.fade-exit.fade-exit-active{
  opacity: 0;
  transition: opacity 150ms linear;
}


@media only screen and (max-width: 900px) {
  .cellSection {
    display: unset;
    padding: 0;
    grid-template-columns: unset;
}
}